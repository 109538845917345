import React, { memo } from 'react'
import PropTypes from 'prop-types'
import type { IconProps } from '@tamagui/helpers-icon'
import {
  Svg,
  Circle as _Circle,
  Ellipse,
  G,
  LinearGradient,
  RadialGradient,
  Line,
  Path,
  Text as _Text,
} from 'react-native-svg'
import { themed } from '@tamagui/helpers-icon'

const Icon = (props) => {
  const { color = 'black', size = 24, ...otherProps } = props
  return (
    <Svg width="19" height={size} viewBox="0 0 19 24" fill={color}>
      <Path
        d="M9.33333 6.36364C9.88341 6.36364 10.411 6.14015 10.7999 5.74235C11.1889 5.34454 11.4074 4.805 11.4074 4.24242C11.4116 3.85634 11.3072 3.47716 11.1067 3.15L9.33333 0L7.56 3.15C7.35949 3.47716 7.25511 3.85634 7.25926 4.24242C7.2609 4.80449 7.47995 5.34305 7.86856 5.74049C8.25716 6.13793 8.78376 6.36196 9.33333 6.36364ZM14.1037 16.9591L12.9941 15.8242L11.8741 16.9591C11.1907 17.6255 10.2827 17.9973 9.33852 17.9973C8.3943 17.9973 7.48632 17.6255 6.80296 16.9591L5.69333 15.8242L4.56296 16.9591C4.23115 17.2995 3.83673 17.5695 3.40245 17.7533C2.96817 17.9372 2.50261 18.0313 2.03259 18.0303C1.30681 18.029 0.598343 17.8035 0 17.3833V22.2727C0 22.554 0.109259 22.8238 0.303741 23.0227C0.498223 23.2216 0.761998 23.3333 1.03704 23.3333H17.6296C17.9047 23.3333 18.1684 23.2216 18.3629 23.0227C18.5574 22.8238 18.6667 22.554 18.6667 22.2727V17.3833C18.0683 17.8035 17.3599 18.029 16.6341 18.0303C16.1641 18.0313 15.6985 17.9372 15.2642 17.7533C14.8299 17.5695 14.4355 17.2995 14.1037 16.9591ZM15.5556 9.54545H10.3704V7.42424H8.2963V9.54545H3.11111C2.28599 9.54545 1.49467 9.88068 0.911224 10.4774C0.327777 11.0741 0 11.8834 0 12.7273V14.3606C0.000822844 14.9117 0.215235 15.4399 0.596242 15.8296C0.977248 16.2193 1.49377 16.4386 2.03259 16.4394C2.29853 16.4415 2.56219 16.3891 2.808 16.2853C3.0538 16.1815 3.27676 16.0283 3.4637 15.8348L5.68296 13.5758L7.89185 15.8348C8.27883 16.2126 8.79321 16.4233 9.32815 16.4233C9.86309 16.4233 10.3775 16.2126 10.7644 15.8348L12.9837 13.5758L15.1926 15.8348C15.3801 16.0275 15.6032 16.1801 15.8489 16.2839C16.0945 16.3877 16.3579 16.4405 16.6237 16.4394C17.1625 16.4386 17.679 16.2193 18.0601 15.8296C18.4411 15.4399 18.6555 14.9117 18.6563 14.3606V12.7273C18.6589 12.3096 18.5806 11.8955 18.4258 11.509C18.271 11.1224 18.0429 10.7711 17.7546 10.4752C17.4662 10.1794 17.1235 9.94486 16.746 9.78528C16.3686 9.6257 15.964 9.54418 15.5556 9.54545Z"
        fill={color}
      />
    </Svg>
  )
}

Icon.displayName = 'CakeIcon'

export const CakeIcon = memo<IconProps>(themed(Icon))
