import { CloseCrossIcon, SettingsIcon } from '@bp/ui/src/Iconsx'
import {
  Adapt,
  Dialog,
  Fieldset,
  Label,
  ScrollView,
  Sheet,
  Spinner,
  Unspaced,
  XStack,
  YStack,
} from 'tamagui'
import { Button, Avatar, Text, TextField } from '@bp/ui'
import { useState } from 'react'
import API from 'app/api'
import { useQueryClient, useMutation } from '@tanstack/react-query'
import { OnboardingImagePickerButton } from 'app/features/onboarding'
import * as ImagePicker from 'expo-image-picker'
import { SolitoImage } from 'solito/image'

export default function EditProfile({ user }) {
  const queryClient = useQueryClient()

  const editMutation = useMutation({
    mutationFn: ({ bio, avatar }) => {
      let partsToUpdate = []
      if (bio !== user?.bio) {
        partsToUpdate.push(API.profile.setBio(bio))
      }
      if (avatar !== user?.avatar.lg) {
        partsToUpdate.push(API.profile.setAvatar(avatar))
      }
      if (background !== user?.page_cover.lg) {
        partsToUpdate.push(API.profile.setBackground(background))
      }
      return Promise.all(partsToUpdate)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['getInfo', user?.username] })
      closeEdit()
    },
  })
  const handleSave = () => {
    editMutation.mutate({ bio, avatar })
  }

  const [isOpen, setIsOpen] = useState(false)
  const handleEdit = () => {
    setIsOpen(true)
  }
  const closeEdit = () => {
    setIsOpen(false)
  }

  const [bio, setBio] = useState(user?.bio)
  const onBioChange = (e) => {
    setBio(e)
  }

  const [avatar, setAvatar] = useState(user?.avatar.lg || '')
  const [background, setBackground] = useState(user?.page_cover.lg || '')

  const onUploadImage =
    (type = 'avatar') =>
    async () => {
      try {
        const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync()
        if (status !== 'granted') {
          return
        }

        const result = await ImagePicker.launchImageLibraryAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.Images,
          allowsEditing: true,
          quality: 1,
          base64: true,
        })

        if (!result.canceled) {
          if (type === 'avatar') {
            setAvatar(`data:image/png;base64,${result.assets[0].base64}`)
          } else {
            setBackground(`data:image/png;base64,${result.assets[0].base64}`)
          }
        }
      } catch (e) {}
    }
  const onCaptureImage =
    (type = 'avatar') =>
    async () => {
      try {
        const { status } = await ImagePicker.requestCameraPermissionsAsync()
        if (status !== 'granted') {
          return
        }

        const result = await ImagePicker.launchCameraAsync({
          allowsEditing: true,
          quality: 1,
          base64: true,
        })

        if (!result.canceled) {
          if (type === 'avatar') {
            setAvatar(`data:image/png;base64,${result.assets[0].base64}`)
          } else {
            setBackground(`data:image/png;base64,${result.assets[0].base64}`)
          }
        }
      } catch (e) {}
    }

  return (
    <Dialog modal open={isOpen}>
      <Dialog.Trigger asChild>
        <Button onPress={handleEdit} variant="outlined" size="$3" color="$secondaryDarker">
          <SettingsIcon color="$secondaryDarker" />
        </Button>
      </Dialog.Trigger>
      <Adapt when="sm" platform="touch">
        <Sheet
          zIndex={200000}
          modal
          dismissOnSnapToBottom
          dismissOnOverlayPress={false}
          onOpenChange={(state) => setIsOpen(state)}
        >
          <Sheet.Frame padding="$4" gap="$4">
            <Adapt.Contents />
          </Sheet.Frame>
          <Button
            position="absolute"
            top="$3"
            right="$3"
            size="$2"
            circular
            icon={CloseCrossIcon}
            onPress={closeEdit}
          />
          <Sheet.Overlay enterStyle={{ opacity: 0 }} exitStyle={{ opacity: 0 }} />
        </Sheet>
      </Adapt>
      <Dialog.Portal>
        <Dialog.Overlay
          key="overlay"
          opacity={0.5}
          enterStyle={{ opacity: 0 }}
          exitStyle={{ opacity: 0 }}
        />
        <Dialog.Content
          bordered
          elevate
          key="content"
          animateOnly={['transform', 'opacity']}
          enterStyle={{ x: 0, y: -20, opacity: 0, scale: 0.9 }}
          exitStyle={{ x: 0, y: 10, opacity: 0, scale: 0.95 }}
          gap="$6"
        >
          <Dialog.Title>Edit profile</Dialog.Title>

          <ScrollView>
            <YStack gap="$6">
              <Fieldset gap="$4" horizontal>
                <Label width={120} justifyContent="flex-end" htmlFor="background">
                  Background
                </Label>
                <YStack alignItems="center" gap="$3">
                  <SolitoImage width={160} height={100} src={background} />
                  <OnboardingImagePickerButton
                    onUploadImage={onUploadImage('background')}
                    onCaptureImage={onCaptureImage('background')}
                  />
                </YStack>
              </Fieldset>

              <Fieldset gap="$4" horizontal>
                <Label width={120} justifyContent="flex-end" htmlFor="avatar">
                  Avatar
                </Label>
                <YStack alignItems="center" gap="$3">
                  <Avatar circular size="$5" accessibilityLabel="Avatar" src={avatar} />
                  <OnboardingImagePickerButton
                    onUploadImage={onUploadImage('avatar')}
                    onCaptureImage={onCaptureImage('avatar')}
                  />
                </YStack>
              </Fieldset>

              <Fieldset gap="$4" horizontal>
                <Label width={120} justifyContent="flex-end" htmlFor="name">
                  Bio
                </Label>
                <TextField width={200} multiline defaultValue={bio} onChangeText={onBioChange} />
              </Fieldset>

              <XStack alignSelf="flex-end" gap="$4">
                <Button
                  iconAfter={editMutation.isPending ? <Spinner /> : undefined}
                  onPress={handleSave}
                  variant="primary"
                >
                  Save changes
                </Button>
              </XStack>
            </YStack>
          </ScrollView>

          <Unspaced>
            <Dialog.Close asChild>
              <Button
                position="absolute"
                top="$3"
                right="$3"
                size="$2"
                circular
                icon={CloseCrossIcon}
                onPress={closeEdit}
              />
            </Dialog.Close>
          </Unspaced>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog>
  )
}
