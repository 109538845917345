import React, { memo } from 'react'
import PropTypes from 'prop-types'
import type { IconProps } from '@tamagui/helpers-icon'
import {
  Svg,
  Circle as _Circle,
  Ellipse,
  G,
  LinearGradient,
  RadialGradient,
  Line,
  Path,
  Text as _Text,
} from 'react-native-svg'
import { themed } from '@tamagui/helpers-icon'

const Icon = (props) => {
  const { color = 'black', size = 24, ...otherProps } = props
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill={color} {...props}>
      <Path
        d="M14.7273 12C15.4465 12 16.1495 11.7947 16.7475 11.4101C17.3455 11.0256 17.8116 10.4789 18.0868 9.83939C18.3621 9.19985 18.4341 8.49612 18.2938 7.81719C18.1535 7.13825 17.8071 6.51461 17.2986 6.02513C16.79 5.53564 16.1421 5.2023 15.4367 5.06725C14.7313 4.9322 14.0002 5.00152 13.3357 5.26642C12.6712 5.53133 12.1033 5.97993 11.7037 6.55551C11.3042 7.13108 11.0909 7.80777 11.0909 8.5C11.0909 9.42826 11.474 10.3185 12.156 10.9749C12.8379 11.6313 13.7628 12 14.7273 12ZM6.54545 10.25V7.625H4.72727V10.25H2V12H4.72727V14.625H6.54545V12H9.27273V10.25H6.54545ZM14.7273 13.75C12.3 13.75 7.45455 14.9225 7.45455 17.25V19H22V17.25C22 14.9225 17.1545 13.75 14.7273 13.75Z"
        fill={color}
      />
    </Svg>
  )
}

Icon.displayName = 'PersonAddIcon'

export const PersonAddIcon = memo<IconProps>(themed(Icon))
