import {
  USERNAME_MIN_LENGTH,
  USERNAME_MAX_LENGTH,
  DISPLAYNAME_MIN_LENGTH,
  DISPLAYNAME_MAX_LENGTH,
} from 'app/utils/constants'

export const getUsernameValidationError = (value) => {
  if (!value) {
    return 'Please enter a username'
  }

  const username = value.trim()

  const usernameFormat = /^[a-zA-Z0-9_-]+$/g
  if (!usernameFormat.test(username)) {
    return `Username can only contain letters, numbers, underscores, and hyphens`
  }

  if (username.length < USERNAME_MIN_LENGTH || username.length > USERNAME_MAX_LENGTH) {
    return `Username must be between ${USERNAME_MIN_LENGTH} and ${USERNAME_MAX_LENGTH} characters long`
  }
  return false
}

export const getDisplaynameValidationError = (value) => {
  if (!value) {
    return 'Please enter a Display Name'
  }

  const displayNameFormat = /^[a-zA-Z0-9_-\s!@#$^&*()+=\[\]:,.?]+$/g
  if (!displayNameFormat.test(value)) {
    return `The Display Name cannot have any of the following special characters: <, >, ", ', ;, %, {, or |.`
  }

  if (
    value.trim().length < DISPLAYNAME_MIN_LENGTH ||
    value.trim().length > DISPLAYNAME_MAX_LENGTH
  ) {
    return `Display Name must be between ${DISPLAYNAME_MIN_LENGTH} and ${DISPLAYNAME_MAX_LENGTH} characters long`
  }

  return false
}

export const validateEmail = (value) => {
  // Allow wildcards in email (ex: jamesbond+007@gmail.com)
  const emailRegexWithWildcard =
    /^[a-zA-Z0-9._-]+(\+[a-zA-Z0-9._-]+)?@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

  // Do not allow wildcards in email (ex: jamesbond+007@gmail.com)
  const emailRegexWithoutWildcard = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

  const emailRegex =
    process.env.APP_ENV === 'production' ? emailRegexWithoutWildcard : emailRegexWithWildcard

  if (!emailRegex.test(value)) {
    return false
  }
  return true
}
