import { XStack, Button } from '@bp/ui'
import { PersonAddIcon, EmailIcon } from '@bp/ui/src/Iconsx'
import EditProfile from './editProfile'

export default function UserProfileAvatar({
  user,
  isSuspended,
  followed,
  handleMessage,
  handleFollow,
  self,
  nativeHeaderStyles,
  ...rest
}) {
  let styles = {
    followVariant: followed ? 'primary' : 'outlined',
    backgroundColor: undefined,
    backgroundColorOutlined: '$lighter',
    borderColor: undefined,
    iconFollowed: '$lighter',
    icon: '$secondaryDarker',
    iconOutlined: '$secondaryDarker',
  }
  if (nativeHeaderStyles) {
    styles = {
      followVariant: followed ? 'outlined' : 'outlined',
      backgroundColor: 'transparent',
      backgroundColorOutlined: 'transparent',
      borderColor: '$lighter',
      icon: '$lighter',
      iconOutlined: '$lighter',
    }
  }
  return (
    <XStack gap="$3" mt="$9" {...rest}>
      {self ? (
        <EditProfile user={user} />
      ) : (
        !isSuspended && (
          <>
            <Button
              onPress={handleFollow(user)}
              variant={styles.followVariant}
              size="$3"
              {...(styles.backgroundColor && { backgroundColor: styles.backgroundColor })}
              {...(styles.borderColor && { borderColor: styles.borderColor })}
            >
              {followed ? (
                <PersonAddIcon color={styles.iconFollowed} />
              ) : (
                <PersonAddIcon color={styles.icon} />
              )}
            </Button>
            <Button
              onPress={handleMessage}
              variant="outlined"
              size="$3"
              color={styles.icon}
              backgroundColor={styles.backgroundColorOutlined}
              borderColor={styles.borderColor}
            >
              <EmailIcon color={styles.iconOutlined} />
            </Button>
          </>
        )
      )}
    </XStack>
  )
}
