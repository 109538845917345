import React, { memo } from 'react'
import PropTypes from 'prop-types'
import type { IconProps } from '@tamagui/helpers-icon'
import {
  Svg,
  Circle as _Circle,
  Ellipse,
  G,
  LinearGradient,
  RadialGradient,
  Line,
  Path,
  Text as _Text,
} from 'react-native-svg'
import { themed } from '@tamagui/helpers-icon'

const Icon = (props) => {
  const { color = 'black', size = 24, ...otherProps } = props
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill={color} {...props}>
      <Path
        d="M19.3161 2V4.72727H22V6.54545H19.3161V9.27273H17.5268V6.54545H14.8429V4.72727H17.5268V2H19.3161ZM12.159 12.9091C12.5129 12.9091 12.8588 12.8025 13.1531 12.6027C13.4473 12.4029 13.6766 12.1189 13.8121 11.7867C13.9475 11.4545 13.9829 11.0889 13.9139 10.7362C13.8448 10.3835 13.6744 10.0595 13.4242 9.80526C13.174 9.55098 12.8551 9.37782 12.5081 9.30766C12.161 9.23751 11.8012 9.27351 11.4743 9.41113C11.1473 9.54874 10.8679 9.78178 10.6713 10.0808C10.4747 10.3798 10.3697 10.7313 10.3697 11.0909C10.3711 11.5727 10.5601 12.0343 10.8953 12.375C11.2306 12.7156 11.6849 12.9077 12.159 12.9091ZM13.9483 4.04545V7.45455H16.6322V10.1818H19.2445C19.2912 10.5435 19.3151 10.9079 19.3161 11.2727C19.3161 14.2909 16.9304 17.8667 12.159 22C7.39057 17.8636 5.00487 14.2879 5.00189 11.2727C4.97649 10.1482 5.20763 9.03287 5.67722 8.01422C6.1468 6.99558 6.84208 6.10123 7.7086 5.40122C8.57512 4.7012 9.58936 4.21453 10.6719 3.97932C11.7544 3.74411 12.8758 3.76675 13.9483 4.04545Z"
        fill={color}
      />
    </Svg>
  )
}

Icon.displayName = 'AddLocationAltIcon'

export const AddLocationAltIcon = memo<IconProps>(themed(Icon))
