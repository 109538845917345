import { SolitoImage } from 'solito/image'
import { YStack } from '@bp/ui'
import { PROFILE_IMAGE_HEIGHT } from './userProfile'

export default function UserProfileHeader({ children, user }) {
  return (
    <YStack width="100%">
      <YStack height={PROFILE_IMAGE_HEIGHT}>
        <SolitoImage resizeMode="cover" src={user?.page_cover.lg} fill />
      </YStack>
      {children}
    </YStack>
  )
}
