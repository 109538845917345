import { Button, Text, XStack, YStack, Separator, isWeb } from '@bp/ui'
import React, { useState, useEffect } from 'react'
import { useRouter } from 'solito/router'
import { useQuery } from '@tanstack/react-query'
import API from 'app/api'
import {
  AddLocationAltIcon,
  ChatIcon,
  PersonAddIcon,
  BlackPlanetIcon,
  CakeIcon,
  VisibilityIcon,
} from '@bp/ui/src/Iconsx'
import Feed from '../feeds/feed'
import { ACCOUNT_STATUS, PROFILE_STATUS } from 'app/utils/constants'
import { Linking, Pressable } from 'react-native'
import UserProfileHeader from './userProfileHeader'
import UserProfileAvatar from './userProfileAvatar'
import UserProfileActions from './userProfileActions'
import { useAuth } from 'app/lib/auth'

export const PROFILE_IMAGE_HEIGHT = 200
export const PROFILE_IMAGE_HEIGHT_MIN = 100

function UserProfile({ uname }) {
  const { user: isAuthenticated } = useAuth()
  const { push } = useRouter()
  const {
    isPending,
    error,
    data: user,
  } = useQuery({
    queryKey: ['getInfo', uname],
    queryFn: () => API[isAuthenticated ? 'profile' : 'public'].getInfo(uname),
  })
  let [followed, setFollowed] = useState(false)
  let [self, setSelf] = useState(false)
  useEffect(() => {
    setFollowed(user?.viewer?.is_follower)
    setSelf(user?.viewer?.self)
  }, [user])

  if (isPending) return <Text>Loading...</Text>
  if (error) return <Text>An error has occurred: {error.message}</Text>

  const isSuspended = user?.profile_status === ACCOUNT_STATUS.suspended
  const isPrivate = isAuthenticated
    ? user.profile_status === PROFILE_STATUS.private && user.viewer?.can_see_posts === false
    : user.profile_status === PROFILE_STATUS.private

  const handleFollow = (userToFollow) => (e) => {
    try {
      if (followed) {
        API.user.unfollow(userToFollow.id)
        setFollowed(false)
      } else {
        API.user.follow(userToFollow.id)
        setFollowed(true)
      }
    } catch (e) { }
  }

  const handleMessage = async () => {
    push({
      pathname: '/messages',
      query: {
        uid: user?.id?.toString(),
      },
    })
  }

  const headerProps = {
    user,
    self,
    isSuspended,
    followed,
    handleFollow,
    handleMessage,
  }

  return (
    <UserProfileHeader {...headerProps}>
      <YStack padding="$4" mt="$-9">
        <XStack justifyContent="space-between">
          <UserProfileAvatar user={user} opacity={isWeb ? 1 : 0} />
          <UserProfileActions {...headerProps} opacity={isWeb ? 1 : 0} />
        </XStack>

        <YStack gap="$1" mt="$3">
          <Text variant="H4">{user.nickname}</Text>
          <Text color="$medium">@{user.username}</Text>
        </YStack>

        {!isSuspended && (
          <XStack justifyContent="center" gap="$6" my="$5">
            <XStack alignItems="center" gap="$2">
              <BlackPlanetIcon color="$mediumDark" />
              <Text verticalAlign="middle" color="$mediumDark">
                Since {user.since}
              </Text>
            </XStack>
            {user.birthday && (
              <XStack alignItems="center" gap="$2" mt="$-1.5">
                <CakeIcon color="$mediumDark" />
                <Text verticalAlign="middle" color="$mediumDark">
                  {user.birthday}
                </Text>
              </XStack>
            )}
          </XStack>
        )}

        {user.bio && !isSuspended && <Text>{user.bio}</Text>}

        <Separator mb="$4" mt="$4" />

        {!isSuspended ? (
          <>
            <YStack
              gap="$3"
              $gtXs={{
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              {user.location && (
                <XStack alignItems="center" gap="$2">
                  <AddLocationAltIcon color="$secondaryDarker" />
                  <Text verticalAlign="middle" bold color="$darker">
                    {user.location}
                  </Text>
                </XStack>
              )}
              <XStack alignItems="center" gap="$2">
                <PersonAddIcon color="$secondaryDarker" />
                <Text verticalAlign="middle" bold color="$darker">
                  {user.counts?.followers} Follower{user.counts?.followers == 1 ? '' : 's'}
                </Text>
              </XStack>
              <XStack alignItems="center" gap="$2">
                <PersonAddIcon color="$secondaryDarker" />
                <Text verticalAlign="middle" bold color="$darker">
                  {user.counts?.following} Following
                </Text>
              </XStack>
              <XStack alignItems="center" gap="$2">
                <ChatIcon color="$secondaryDarker" />
                <Text verticalAlign="middle" bold color="$darker">
                  {user.counts?.posts} Post{user.counts?.posts == 1 ? '' : 's'}
                </Text>
              </XStack>
            </YStack>

            <Separator mb="$4" mt="$4" />

            {
              /* TODO:GS data is not visible to guests, it will be checked in the SSR ticket */
              isPrivate ? (
                <PrivateProfileMessage />
              ) : isAuthenticated ? (
                <Feed userId={user.id} feedSlug={self ? 'user' : 'published'} />
              ) : null
            }
          </>
        ) : (
          <SuspendedAccountMessage />
        )}
      </YStack>
    </UserProfileHeader>
  )
}

const SuspendedAccountMessage = () => (
  <YStack alignItems="center" gap="$3" padding="$4">
    <Text variant="H4" centered fontWeight="bold">
      This account has been suspended
    </Text>
    <Text color="$medium" centered lineHeight={20}>
      BlackPlanet suspends accounts which violate the{' '}
      <Pressable
        style={{ display: 'inline' }}
        onPress={() => Linking.openURL('https://urban1.com/terms-of-service/')}
      >
        <Text>Terms and Conditions.</Text>
      </Pressable>
    </Text>
  </YStack>
)

const PrivateProfileMessage = () => (
  <YStack alignItems="center" gap="$3" padding="$4">
    <VisibilityIcon color="$mediumDark" size={40} />
    <Text variant="H4" centered fontWeight="bold">
      This profile is private
    </Text>
  </YStack>
)

export default UserProfile
