import React, { memo } from 'react'
import PropTypes from 'prop-types'
import type { IconProps } from '@tamagui/helpers-icon'
import {
  Svg,
  Circle as _Circle,
  Ellipse,
  G,
  LinearGradient,
  RadialGradient,
  Line,
  Path,
  Text as _Text,
} from 'react-native-svg'
import { themed } from '@tamagui/helpers-icon'

const Icon = (props) => {
  const { color = 'black', size = 24, ...otherProps } = props

  // Bp Logo icon ratio is 4:3 (24x18)
  // Convert view box height as per ratio
  const height = (size * 3) / 4

  return (
    <Svg width={size} height={size} viewBox={`0 0 24 ${height}`} fill={color}>
      <Path
        d="M23.9815 6.8456C23.7718 5.83964 21.824 5.2949 18.9552 5.26207C19.0049 4.98494 19.0299 4.70407 19.0296 4.42268C19.03 -5.02487e-06 14.6741 0 11.3156 0H5.77978V7.56178C2.11528 8.85105 -0.234128 10.4212 0.0185529 11.6363C0.245594 12.7262 2.51188 13.2741 5.77978 13.217V13.2215C8.24153 13.189 10.6941 12.9207 13.1026 12.4202C13.7466 12.2913 14.3748 12.152 14.9871 12.0023C13.8023 12.4526 12.5492 12.883 11.2453 13.2827C9.35203 13.8622 7.51009 14.3279 5.77978 14.6765V18H12.0244C15.5661 18 20.0002 18 20.0002 13.0378C20.0086 12.1504 19.7698 11.2775 19.3096 10.5132C22.3409 9.30985 24.2077 7.93547 23.9815 6.8456ZM5.77978 12.4481C3.77777 12.3586 2.44736 11.9257 2.29186 11.1816C2.11569 10.3345 3.49945 9.27662 5.77978 8.33023V12.4481ZM18.8452 9.87809C18.1454 9.08476 17.21 8.52582 16.1699 8.27957C16.7447 8.13558 17.2762 7.85955 17.7206 7.47432C18.1649 7.08908 18.5093 6.60571 18.7252 6.06417C20.4431 6.19995 21.5659 6.62024 21.7078 7.29994C21.867 8.06556 20.7487 9.00669 18.8452 9.87809Z"
        fill={color}
      />
    </Svg>
  )
}

Icon.displayName = 'BlackPlanetIcon'

export const BlackPlanetIcon = memo<IconProps>(themed(Icon))
