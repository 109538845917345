import React, { memo } from 'react'
import PropTypes from 'prop-types'
import type { IconProps } from '@tamagui/helpers-icon'
import {
  Svg,
  Circle as _Circle,
  Ellipse,
  G,
  LinearGradient,
  RadialGradient,
  Line,
  Path,
  Text as _Text,
} from 'react-native-svg'
import { themed } from '@tamagui/helpers-icon'

const Icon = (props) => {
  const { color = 'black', size = 24, ...otherProps } = props
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill={color} {...props}>
      <Path
        d="M12 5C9.84134 5.00132 7.73305 5.66974 5.94951 6.91827C4.16597 8.1668 2.78976 9.93762 2 12.0003C2.78879 14.0636 4.16477 15.835 5.94855 17.0836C7.73232 18.3322 9.84112 19 12 19C14.1589 19 16.2677 18.3322 18.0515 17.0836C19.8352 15.835 21.2112 14.0636 22 12.0003C21.2102 9.93762 19.834 8.1668 18.0505 6.91827C16.2669 5.66974 14.1587 5.00132 12 5ZM12 16.6671C11.101 16.6671 10.2222 16.3934 9.47468 15.8806C8.72718 15.3678 8.14458 14.639 7.80055 13.7862C7.45651 12.9334 7.3665 11.9951 7.54189 11.0898C7.71727 10.1845 8.15019 9.35298 8.78588 8.70031C9.42157 8.04764 10.2315 7.60317 11.1132 7.4231C11.995 7.24302 12.9089 7.33544 13.7395 7.68867C14.57 8.04189 15.2799 8.64005 15.7794 9.40751C16.2789 10.175 16.5455 11.0773 16.5455 12.0003C16.5455 13.238 16.0666 14.425 15.2141 15.3002C14.3617 16.1754 13.2055 16.6671 12 16.6671ZM12 9.20016C11.4606 9.20016 10.9333 9.36439 10.4848 9.67207C10.0363 9.97975 9.68675 10.4171 9.48033 10.9287C9.27391 11.4404 9.2199 12.0034 9.32513 12.5465C9.43036 13.0897 9.69011 13.5886 10.0715 13.9802C10.4529 14.3718 10.9389 14.6385 11.4679 14.7466C11.997 14.8546 12.5453 14.7992 13.0437 14.5872C13.542 14.3753 13.968 14.0164 14.2676 13.5559C14.5673 13.0955 14.7273 12.5541 14.7273 12.0003C14.7273 11.2576 14.4399 10.5454 13.9285 10.0203C13.417 9.49517 12.7233 9.20016 12 9.20016Z"
        fill={color}
      />
    </Svg>
  )
}

Icon.displayName = 'VisibilityIcon'

export const VisibilityIcon = memo<IconProps>(themed(Icon))
