import { Avatar } from 'tamagui'

export default function UserProfileAvatar({ user, ...rest }) {
  return (
    <Avatar circular size={140} {...rest}>
      <Avatar.Image
        accessibilityLabel={`${user.username} avatar`}
        src={user.avatar.lg || user.avatar.md}
      />
      <Avatar.Fallback backgroundColor="$background" />
    </Avatar>
  )
}
