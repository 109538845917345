import { Button, Text, YStack } from '@bp/ui'
import { ChevronLeft } from '@tamagui/lucide-icons'
import React from 'react'
import { createParam } from 'solito'
import { useLink } from 'solito/link'
import UserProfile from './userProfile'
import Head from 'next/head'
import { getUsernameValidationError } from 'app/utils/validation'

const { useParam } = createParam<{ uname: string }>()

export function UserPage() {
  const [uname] = useParam('uname')
  const link = useLink({
    href: '/',
  })

  // If the username is invalid - display an error page
  // There is no way that this user exists, right? We can fail early, no need to fetch the API.
  if (getUsernameValidationError(uname)) {
    return (
      <YStack gap="$6" flex={1} justifyContent="center" alignItems="center" padding="$4">
        <Head>
          <title>404 | BlackPlanet</title>
        </Head>
        <Text textAlign="center">User {uname} doesn't exist on BlackPlanet.</Text>
        <Button {...link} icon={ChevronLeft}>
          Go to the Homepage
        </Button>
      </YStack>
    )
  }

  // TODO: Waiting for confirmation on the profile page title and title bar for Native.
  // TODO: Initial render Title is not right, but that will be handled in https://ionestudios.atlassian.net/browse/BLAC-828
  return (
    <YStack flex={1} justifyContent="flex-start" alignItems="center">
      <Head>
        <title>{uname} on BlackPlanet</title>
      </Head>
      <UserProfile uname={uname} />
    </YStack>
  )
}
